.gto-2022 {
    background-color: #E4ECFA;
    /* padding: 39px 10px; */
    color: #000F9F !important;
}

.grey-text {
    color: #9e9e9e !important;
}

.grey-text.text-darken-2 {
    color: #616161 !important;
}

.btn-group .btn {
    border-radius: 0px;
}

.btn-floating {
    z-index: 1;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000f9f;
    margin: 1em;
    cursor: pointer;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    display: inline-block;
}

.btn-floating:hover {
    background-color: #3b679c;
}

.btn-floating i {
    width: inherit;
    font-size: 1.5rem;
    line-height: 50px;
    text-align: center;
}

.btn-floating i {
    display: inline-block;
    color: #fff;
}

#top-bar-sticky-wrapper {
    height: 51px;
    /* background-color: var(--azul-marino-alt); */
    background-color: #E4ECFA;
    padding: 2px;
    line-height: 0.5rem;
}

.bg-blue-s,
.fixed-top {
    background-color: #0262c9;
}

#navbarNav a {
    font-size: 14px;
    padding: 1em 1em;
    letter-spacing: 1px;
    line-height: 20px;
}

nav {
    margin-bottom: 10px;
}

#nav.affix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
}

.gto-blue {
    background-color: #000F9F !important;
}

.divider {
    width: 3px;
    height: 95%;
    float: left;
    background-color: #9e9e9e;
    margin: 10px;
}

@media (max-width: 767px) {
    .divider {
        width: 95%;
        height: 3px;
        background-color: #9e9e9e;
        margin: 10px;
    }
}