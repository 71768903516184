.tableContainer{
    width: 100%;
    height: 420px;
    padding: 0 10px 10px 10px;
    overflow: scroll;
}

.tbHead{
    /* position: sticky; */
    top: 0px;
    margin: 0 0 0 0;    
}
